@import '../../../static/styles/variables';
@import '../../../static/styles/mediaqueries';

.notification {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  img {
    width: 60px;
    margin-right: 10px;
  }

  &__content {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    @include media(l-device) {
      width: 90%;
    }

    img {
      width: 40px;
    }

    & > span {
      padding-left: 10px;
    }

    h3 {
      font-size: 1rem;
      font-family: $baderNarrowBold;
      color: $black;
    }

    p {
      font-family: $baderNarrow;
      font-size: 1rem;
      color: $p-color;
      padding-top: 8px;
    }

  }

  &__wrapper {
    padding: 10px 15px;
  }

}