@import "../../../static/styles/variables";

.input {
  &__errors {
    color: $warning;
    font-size: 14px;
    font-family: $baderNarrow;
    padding-left: 15px;
  }
}
