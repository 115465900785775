@import "../../../static/styles/variables";
@import "../input/Input.module";

.test {
  position: relative;
}


.input {

  &__date {

    border: none;
    outline: none;
    font-family: $baderNarrow;

    &::placeholder {
      color: $placeholder-color !important;
    }

    &__wrapper {
      display: flex;
      align-items: center;
      position: relative;
      padding: 0;
      margin-bottom: 5px;
    }

    &__icon {
      outline: none;
      border: none;
      background-color: transparent;
      cursor: pointer;
      position: absolute;
      right: 3%;
      padding: 0;
      margin: 0;

      &:hover, &:focus {
        outline: none;
        border: none;
      }

      img {
        color: $primary;
        font-size: 1.5rem;
      }

    }
  }
}

.label_inline_block {
  display: inline-block;
}

.tooltip_orange {
  z-index: 99;
  position: absolute;
  left: 93%;
  top: 26px;

  color: $white;
  background-color: $primary;
  width: 30px;
  height: 30px;
  border-radius: 20%;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  font-size: 14px;
  font-family: $baderNarrowBold;
}

/*
:global {
  .SingleDatePicker {
    width: 100%;

    .SingleDatePickerInput {
      width: 100%;
      border: none;

      .DateInput {
        width: 100%;
        border: none;

        .DateInput_input {
          width: 100%;
          height: 50px;
          padding: 1rem;
          color: $black;
          font-size: 1rem;

          border: none;
          border-radius: 4px;
          outline: none;
          background-color: #ffffff;

          font-family: $baderNarrow;


          &::placeholder {
            color: $placeholder-color;
            font-style: italic;
            font-size: 16px;
            font-family: $baderNarrow;
          }

          &__disabled {
            background-color: $very-light-grey !important;
            font-style: italic !important;
          }
        }
      }

      .SingleDatePicker_picker {
        .DayPicker {
          .DayPicker_weekHeaders {
            .DayPicker_weekHeader {
              top: 57px;
            }
          }

          .DayPicker_focusRegion {

            .DayPickerNavigation {
              z-index: 4;
            }

            .DayPickerKeyboardShortcuts_buttonReset {
              display: none;
            }

            .CalendarMonth_caption {
              padding-bottom: 40px;
            }

            .CalendarMonthGrid {
              background: transparent;
              z-index: 3;

              .CalendarMonth {
                background: transparent;
              }
            }

            .CalendarMonth_table {
              tr {
                td {
                  vertical-align: middle;

                  &.CalendarDay__selected, &.CalendarDay__selected:active, &.CalendarDay__selected:active {
                    background: $primary;
                    border: 1px solid $primary;
                  }

                  &.CalendarDay__blocked_calendar, &.CalendarDay__blocked_calendar:active, &.CalendarDay__blocked_calendar:hover {
                    background: #fff;
                    border: 1px solid #e4e7e7;
                    color: #cacccd;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
*/

.border__no_error {
  border: 1px solid $very-light-pink;
  border-radius: 4px;
  &__focused {
    border-color: $placeholder-color;
  }
}

.border__error {
  border: solid 1px $warning;
  border-radius: 4px;
  padding: 1px;
}


.month_year_select {
  padding: 0 !important;
  margin: 0 !important;
  position: relative !important;
  top: -8px !important;

  label {
    padding: 0px !important;
  }
  width: auto;
}
