@import '../../../static/styles/variables';
@import '../../../static/styles/mediaqueries';

.footer {
  width: 100%;
  height: 100px;
  padding: 20px;
  position: relative;
  background-color: $primary-bg;
  padding-top: 0;
  display: flex;
  justify-content: center;

  @include media(m-device) {
    padding: 24px;
    padding-top: 0;
  }


  @include media(m-device) {
    justify-content: flex-start;
    align-items: flex-start;
    height: auto;
    border: none;

  }

  &_sitemap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 65%;
    border-top: 1px solid rgba(0, 0, 0, .4);;
    @include media(m-device) {
      flex-direction: column;
      align-items: flex-start;
      border: none;
      width: 100%;
    }

    li {
      @include media(m-device) {
        padding: 16px 0;
      }
      a {
        span {
                cursor: pointer;
            }
        }
    }

    span {
      padding: 0;
      margin-top: -3px;
      font-family: $baderNarrowBold;
    }
  }

}

.phone {

  width: 233px;
  height: 29px;
}
