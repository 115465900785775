@import '../../static/styles/mediaqueries';
@import '../../static/styles/variables';
@import '../button/simpleButton/Button.module';

.nav {
  width: 100%;
  height: 120px;

  display: flex;
  justify-content: flex-start;
  align-items: center;

  padding: 1rem 4rem;

  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 99;

  background-color: $primary-bg;
  &__logo{
    display: flex;
  }

  @include media(m-device) {
    height: 54px;
    padding: 0 10px;
    justify-content: space-between;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.35);
    background-color: $white;

  }

  @include media(ie) {
    background-color: $white;
    padding: 0 20px;
    width: 100%;
  }

  &::before {
    content: "";
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 80px;
    z-index: 0;
    background-color: $white;

    transition: transform ease-in-out .2s;
    transform: translateY(-80px);

    @include media(m-device) {
      height: 54px;
      transform: translateY(-4rem);
    }
  }

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 4rem;
    width: calc(100% - 8rem);
    height: 1px;
    display: block;
  }


  &__bars {
    display: none;
    position: relative;
    z-index: 99;

    @include media(m-device) {
      display: block;
    }
  }

  &__login {
    width: 15px;
    height: 15px;

    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 1.5rem;

    @include media(l-device) {
      border: 1px solid #dcdcdc;
      background-color: $white;
    }
  }

  &__group {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;

    &__search {
      height: 40px;
      width: 40px;
      margin-right: 10px;

      @include media(l-device) {
        display: none;
      }

      &__icon {
        cursor: pointer;
        width: 100%;
        height: 100%;
      }
    }
  }


  &__menu {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    position: relative;
    z-index: 90;

    .btn__primary {
      height: 48px;
      min-width: fit-content;
      margin-right: 5px;
    }

    @include media(l-device) {
      padding-left: 10px;
    }

    @include media(m-device) {
      width: 100%;
      height: 100vh;

      flex-direction: column;
      justify-content: flex-start;

      padding: 1rem 0;

      position: absolute;
      top: 60px;
      left: -150px;
      opacity: 0;
      z-index: 101;

      background-color: $white;
      box-shadow: 0 3px 4px 0px rgba(0, 0, 0, 0.35);
      transform: translateX(-100%);

      .nav__group {
        flex-direction: column;
        align-items: flex-start;
        padding-bottom: 1rem;
      }

      .navlink_first {
        padding-top: 0;
      }

      .navlink_alternate {
        background-color: $very-light-grey;
      }

      .menu_btn {
        height: 50px;
        margin: 16px 5% 16px 7.5%;
        width: 78.6%;
        @media (max-width: 450px) {
          width: 78.6%;
          margin-left: 6%;
          margin-right: 4%;
        }
      }
    }
  }

  &__close {
    display: none;
    position: relative;
    z-index: 99;
    @include media(m-device) {
      display: block;
      font-size: 2rem;
      transform: rotate(-90deg);
      transition: transform ease-in-out .6s;
    }
  }

  &__pot {
    &::before {
      width: 65%;
    }
  }

  &__dashboard {
    position: relative;
    width: 100%;
    background-color: $white;
    &::before {
      display: none;
    }
  }

  &__avdn {
      justify-content: space-between;
  }
}

.opened {
  @include media(m-device) {
    opacity: 1;
    transform: translateX(0);
    transition: transform ease-out .6s;
    .nav__close {
      transform: rotate(90deg);
      transition: transform ease-out .6s;
    }
  }
}

.close {
  @include media(m-device) {
    transform: translateX(-100%);
    transition: transform ease-in-out .6s, opacity ease-in-out 1s;
  }
}


.overwrite__center {
    @include media(m-device) {
        margin-right: -50px;
    }
}

.scrolled {
  height: 80px;
  transition: height ease-in-out .2s;

  &::before {
    transform: translateY(0px);
  }

  @include media(m-device) {
    height: 54px;
    transition: none;

    &::before {
      transition: none;
      transform: none;
    }
  }

  .overwrite {
    height: 85px;
  }
}

.navlink__container {
  display: flex;
  margin-left: 30px;
  opacity: 1;
  transition: all 350ms ease-in-out;

  &__hide {
    opacity: 0;
  }

}

