@import "../../../static/styles/variables";
@import "../../../static/styles/mediaqueries";

.account {
    &__btn {
        width: fit-content;
        min-width: 140px;
        height: 50px;
        font-size: 1rem;
        cursor: pointer;

        display: flex;
        justify-content: space-between;
        align-items: center;

        border-radius: 6px;
        border: solid 1px #dcdcdc;
        background-color: #ffffff;
        outline: none;


        @include media(m-device) {
            min-width: 40px;
            margin-right: 2px;
        }

        .auth__btn {
            @extend .account__btn;

            justify-content: center;
            align-items: center;

            .auth__content {
                font-family: $baderCrompress;
                font-size: 1rem;


                @include media(m-device) {
                    display: none;
                }
            }

            img {
                width: 24px;

                @include media(l-device) {
                    margin-right: 5px;
                }
            }
        }
    }


    &__img {
        width: 48px;
        height: 48px;

        border-radius: 50%;

        background-color: rgba(0, 0, 0, 0.1);

        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;

        div {
            width: 100%;
            height: 100%;
            background-size: cover;
            background-position: center;
        }
    }
}

.auth__content {
    font-family: $baderCrompress;
    text-align: center;
    font-size: 1rem;
    margin-top: -4px;
    width: fit-content;


    @include media(m-device) {
        display: none;
    }
}


.auth__btn {
    @extend .account__btn;

    justify-content: center;
    align-items: center;

    @include media(m-device) {
        .auth__content {
            display: none;
        }
    }

    .auth__content {
        font-family: $baderNarrowBold;
        font-size: 1rem;
    }

    img {
        width: 24px;

        @include media(l-device) {
            margin-right: 5px;
        }
    }
}


.account__content {
    @extend .auth__content;
    width: calc(100% - 40px);
}
