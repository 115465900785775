@import "../navbar/login-button/LoginButton.module";

.user_avatar {
    @extend .account__img;
    position: relative;
    overflow: visible !important;
    font-family: $baderCrompress;

    .profile_pic {
        overflow: hidden;
        height: 100%;

    }

    .profile_pic_round {
        border-radius: 50%;

    }

    .profile_pic_squared {
        @include media(l-device) {
            border-radius: 6px 0 0 6px;
        }
        border-radius: 6px;
    }

    .social_tag {
        position: absolute;
        right: 0;
        top: 0;

        width: 30px;
        height: 30px;
        border-radius: 50%;
        padding: 5px;

        background-color: $white;
    }
}
