@import '../../static/styles/variables';
@import '../../static/styles/mediaqueries';
@import '../../static/styles/buttons';

.sidebar {
    width: 100vw;
    position: fixed;
    z-index: 10;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: $white;
    transform: translateY(-100vh);


    &.open {
        transform: translateY(0);
        transition: transform ease-in-out .5s;
    }

    &.close {
        transform: translateY(-100vh);
        transition: transform ease-in-out .4s;
        bottom: 40px;
        visibility: hidden;
    }

    .nav__group {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        padding-top: 55px;

        & > a {
            height: 70px;
            width: 100%;
            font-family: $baderCrompress;
            font-size: 28px;
            line-height: 28px;
            color: $black;
            border-bottom: 1px solid $very-light-pink;
            text-align: center;
            text-transform: uppercase;
            display: flex;
            justify-content: center;
            align-items: center;
            

            &.btn__primary {
                @extend .btn__primary;
                width: 90%;
                max-width: 295px;
                color: $white;
                height: 50px;
                background-color: $primary;
                margin-top: 16px;

            }
        }
    }
}

.overlay {
    height: 375px;
    display: block;
    @include media(l-device) {
        display: none;
    }
}


