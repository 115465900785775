@import '../../../static/styles/variables';
@import '../../../static/styles/mediaqueries';
@import '../../form/input/Input.module';
@import "../../../static/styles/buttons";

.newsletter {
  position: relative;
  padding-top: 24px;
  display: flex;
  width: 65%;
  margin: auto;
  align-items: flex-end;

  @include media(m-device) {
    display: flex;
    width: auto;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 24px;
    padding-top: 40px;
  }
  &__image {
    bottom: 0;
    width:220px;
    height: 260px;
    @include media(m-device){
      width: 170px;
      height: 200px;
    }
  }

  &_content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 0 auto 0 24px;
    width: fit-content;
    padding-top: 40px;
    padding-bottom: 30px;

    @include media(m-device) {
      padding-top: 0;
      margin: 0;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      padding-bottom: 0px;
      &::before {
        content: "";
        width: 100%;
        border-top: 1px solid rgba(47, 30, 0, 0.5);
        margin-bottom: 15px;
      }
    }



    h2 {
      font-family: $baderCrompress;
      font-size: 28px;
      line-height: 49px;
      padding-bottom: 10px;
      text-transform: uppercase;

      @include media(m-device) {
        text-align: left !important;
      }
    }

    p {
      font-family: $baderNarrow;
      font-size: 18px;
      line-height: 28px;
      color: $black;

      @include media(m-device) {
        text-align: justify;
      }
    }
  }

  &_wrapper {
    width: 100%;
  }
  &_form {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 613px;
    height: max-content;
    box-shadow: 0px 4px 40px 0px #00000014;
    margin: 24px 0;


    @include media(m-device) {
      flex-direction: column;
      width: 100%;
      padding: 0;
      margin: 2rem 0 0;
      height: auto;
    }

    .email,
    .date,
    .button {
      width: 276px;
      padding: 0;

      @include media(m-device) {
        width: 100%;
        margin: 0;
      }

      &>input,
      &>button {
        height: 48px; // Ensures the same height for all elements
        margin-bottom: 0;

        @include media(m-device) {
          width: 100%;
          height: 50px;
          margin: 0;

        }
      }
    }

    .email>input {
      border: none;
      border-right: 1px solid #DCDCDC;
      border-radius: 6px 0 0 6px;

      @include media(m-device) {
        border: none;
        border-bottom: 1px solid #DCDCDC;

        border-radius: 4px 4px 0 0;
        margin: 0;
      }
    }

    .date {
      @include media(m-device) {
        .input__date__wrapper {
          padding: 0 !important;
          margin: 0 !important;
        }

        width: 100%;
        margin: 0;
      }
    }

    .button {
      @extend .btn__light;
      width: 117px;
      text-transform: uppercase;
      padding: 0;
      margin: 0;
      height: 48px;
      position: relative;
      border: none;
      border-left: 1px solid #DCDCDC;
      border-radius: 0 6px 6px 0;

      @include media(m-device) {
        position: relative;
        bottom: 0;
        border-radius: 0 0 6px 6px;
        width: 100%;
        border: none;
        border-top: 1px solid #DCDCDC;
      }
    }
  }
}

.mobile {
  display: flex;
  align-items: center;
  justify-content: center;

  @include media(m-device) {
    justify-content: center;
    width: 300px;
    position: absolute;
    bottom: 50px;
    margin-left: auto;
    margin-right: auto;


  }
}

.socialShare {
  width: fit-content;
  border-radius: 6px;
  border: 1px solid rgba(47, 30, 0, 0.5);
  display: flex;
  justify-content: flex-start;

  img {
    width: 24px;
    height: 24px;
  }

  a {
    padding: 10px;
    height: 48px;
    width: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: 1px solid rgba(47, 30, 0, 0.5);

    @include media(m-device) {
      width: 100%;
    }
  }

  a:last-child {
    border-right: none;
  }

  @include media(m-device) {
    display: flex;
    justify-items: center;
    align-items: center;
    margin-top: 24px;
    width: 100%;
    height: 48px;


    a {

      &>img {
        height: 24px;
        width: 24px;
      }
    }

  }

}

.inputDate {
  border: none;
  margin-bottom: 0;
  padding: 0;

  @include media(m-device) {
    margin: 0;
  }

  :global {
    .DateInput_input {
      height: 48px !important;
    }
  }
}