@import '../../../static/styles/mediaqueries';
@import '../../../static/styles/variables';

.navlink {
  margin: 0 1rem;
  font-weight: 700;
  justify-self: flex-start;
  font-family: $baderNarrowBold;
  position: relative;

  &:global {
    &.white {
      span {
        color: $black !important;
      }
    }
  }

  &::before {
    content: "";
    position: absolute;
    left: 0;
    bottom: -8px;
    width: 100%;
    height: 4px;
    background-color: transparent;
    transition: all ease-in-out .3s;
    border-radius: 2px;
  }

  &:hover {
    &::before {
      background-color: $primary;
    }
  }

  &.active {
    &::before {
      background-color: $primary;
    }
  }

  @include media(m-device) {
    padding: 21px 0;
    margin: 0;
    width: 100%;
    text-align: center;
    font-weight: 900;
    font-size: 28px;
    font-family: $baderCrompress;
    text-transform: uppercase;
    border-bottom: 1px solid rgba(102, 102, 102, 0.3);

    &:hover {
      &::before {
        background-color: transparent;
      }
    }

    &.active {
      border-bottom: 2px solid $primary;

      &::before {
        background-color: transparent;
      }
    }
  }

  span:first-letter {
    text-transform: uppercase;
  }
}


