@import "../../static/styles/variables";
@import "../../static/styles/fonts";
@import "../../static/styles/mediaqueries";

.tooltip {
    display: inline-block;

    :global {
        .popup-arrow {
            box-shadow: none !important;
        }

        .popup-content {
            box-shadow: 4px 6px 40px 0 rgba(0, 0, 0, 0.1) !important;
            background-color: $white !important;
            border: none !important;
            padding: 15px !important;
            width: 300px !important;
            color: $black;

            @include media(m-device){
              position: absolute !important;
              left: 1rem !important;
            }

            .tooltip__body {
                font-family: $baderNarrow;
                line-height: 20px;
                padding-right: 10px;
                text-transform: initial;
                font-size: 16px;
                color: $black;
            }
        }

        .popup-overlay {
            z-index: 10;
        }

        .tooltip__trigger {
            background-color: transparent;
            color: $black;
            border: 1px solid $very-light-pink;
            width: 24px;
            height: 24px;
            font-size: 16px;
            border-radius: 3px;
            font-family: $baderNarrow;
            display: inline-block;
            vertical-align: middle;
            text-align: center;
            margin-left: 10px;
            line-height: 10px;
            outline: none;
            cursor: pointer;
            padding: 0;

            &:hover,
            &:focus {
                outline: none;
            }
        }
    }
}
