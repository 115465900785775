@import '../../static/styles/variables';

.navbar_radio {
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    padding: 6px;
    background-color: $warning;
    z-index: 15;
    position: absolute;
    top: -3px;
    right: -4px;

    p {
        font-size: 11px;
        font-family: $baderNarrow;
        font-weight: bold;
        color: $white;
        text-align: center;
    }
}
