@import '../../../static/styles/mediaqueries';

.nav__brand {
  display: flex;
  align-content: center;
  position: relative;
  z-index: 99;

  @include media(m-devices) {
    order: 2;
  }

  img {
    display: block;
    transition: height ease-in-out 1s;
    max-width: 120px;

    @include media(m-device) {
      height: 85px;
      padding: 10px 10px 10px 0;
    }

  }
}