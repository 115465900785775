@import "../../../static/styles/variables";

.label {
  display: block;

  padding-bottom: 10px;

  color: $black;
  font-size: 1rem;
  font-family: $baderNarrowBold;
}

.input {
  width: 100%;
  height: 50px;


  padding: 1rem;
  margin-bottom: 5px;
  color: $black;
  font-size: 1rem;

  border: 1px solid $very-light-pink;
  border-radius: 4px;
  outline: none;
  background-color: #ffffff;

  font-family: $baderNarrow;

  &__error {
    border: 1px solid $warning;
    border-radius: 4px;

  }

  &::placeholder {
    color: $placeholder-color;
    font-style: italic;
    font-size: 16px;
    font-family: $baderNarrow;
  }

  &:focus {
    border-color: #999999;
  }

  &__wrapper {
    padding: 10px 0;
  }

  &__errors {
    color: $warning;
    font-size: 14px;
    font-family: $baderNarrow;
    text-transform: lowercase;
    padding-left: 15px;

    &:first-letter {
      text-transform: uppercase;
    }
  }
}

.disabled_input {
  background-color: $very-light-grey;
}

.tooltip{
  z-index: 9999999999;
  width: 70%;

}