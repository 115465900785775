@import '../../static/styles/mediaqueries';

@import '../../static/styles/variables';

.footer {
  position: sticky;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
  bottom: 0;
  background-color: $primary-bg;
  transition: transform 0.2s linear;

  @include media(m-device) {
    transition: none;
    height: 100vh;
  }
}